// First we need to import axios.js
import axios from 'axios';
import { URL } from './index';
// Next we make an 'instance' of it
const api = axios.create({
// .. where we make our configurations
    baseURL: URL
});

// Where you would set stuff like your 'Authorization' header, etc ...
api.defaults.headers.common['Authorization'] = localStorage.getItem('token_weiots_cms');

// // Also add/ configure interceptors && all the other cool stuff

// instance.interceptors.request...

export default api;