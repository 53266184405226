import React from "react";
import {
  Button,
  makeStyles,
  CircularProgress,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import api from "../../URL/axiosConfig";
import antares from "../../URL/axiosConfigAntares";
import apiIki from "../../URL/axiosConfigIki";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Cards = props => {
  const classes = useStyles();
  const [types, setTypes] = React.useState([]);
  const [middlewares, setMiddlewares] = React.useState([]);
  const [appName, setAppname] = React.useState("");
  const [wmcusername, setWmcUsername] = React.useState("");
  const [wmcpassword, setWmcPassword] = React.useState("");
  const [deveui, setDeveui] = React.useState("");
  const [deveuiDisplay, setDeveuiDisplay] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [middlewareId, setMiddleware] = React.useState("");
  const [check, setCheck] = React.useState(true);
  const [typeId, setType] = React.useState("");
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  const [saveDisable, setSaveDisable] = React.useState(true);
  const inputLabel = React.useRef(null);
  const inputLabel2 = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [labelWidth2, setLabelWidth2] = React.useState(0);

  const [antaresId, setAntaresId] = React.useState("");
  const [deviceName, setDeviceName] = React.useState("");

  const [childAppName, setChildAppName] = React.useState("");

  const [childSn, setChildSn] = React.useState("");
  const [branch, setBranch] = React.useState("sindcond");

  React.useEffect(() => {
    console.log(
      deviceName,
      deveui,
      middlewareId,
      typeId,
      deviceName != "" && deveui != "" && middlewareId == 4 && typeId != "",
    );
    setLabelWidth(inputLabel.current.offsetWidth);
    setLabelWidth2(inputLabel2.current.offsetWidth);
    if (appName != "" && deveui != "" && middlewareId != "" && typeId != "") {
      setSaveDisable(false);
    } else if (
      deviceName != "" &&
      deveui != "" &&
      middlewareId != "" &&
      typeId != "" &&
      antaresId != ""
    ) {
      setSaveDisable(false);
    } else if (
      wmcpassword != "" &&
      wmcusername != "" &&
      deviceName != "" &&
      deveui != "" &&
      (middlewareId == 4 || middlewareId == 6) &&
      typeId != ""
    ) {
      setSaveDisable(false);
    } else setSaveDisable(true);
  }, [appName, deveui, middlewareId, typeId, antaresId, deviceName]);

  React.useEffect(() => {
    const fetchType = async () => {
      const res = await api("/list/type");
      console.log("data", res.data);
      const data = res.data.list_type ? res.data.list_type : [];
      setTypes(data);
    };
    const fetchMw = async () => {
      const res = await api("/list/middleware");
      console.log("data", res.data);
      const data = res.data.messages ? res.data.messages : [];
      setMiddlewares(data);
    };
    fetchType();
    fetchMw();
    setLabelWidth(inputLabel.current.offsetWidth);
    setLabelWidth2(inputLabel2.current.offsetWidth);
  }, []);

  const checkNode = async e => {
    e.preventDefault();
    setLoading(true);
    if (middlewareId == 3) {
      antares({
        method: "POST",
        url: "/node/check",
        data: {
          device_name: deviceName,
        },
      })
        .then(res => {
          setAntaresId(res.data["m2m:cnt"].ri);
          setCheck(false);
          setLoading(false);
        })
        .catch(e => {
          console.log(e);
          setCheck(true);
          setLoading(false);
          alert(e.message + "\r\n not found");
        });
    } else if (middlewareId == 1) {
      api({
        method: "POST",
        url: "/check/node",
        data: {
          devEUI: deveui.replace(/\s/g, "").trim(),
          type: appName,
        },
      })
        .then(res => {
          setCheck(false);
          setLoading(false);
        })
        .catch(e => {
          setCheck(true);
          setLoading(false);
          alert(e.response.data.body.message);
        });
    } else if (middlewareId == 5) {
      api({
        method: "POST",
        url: "/check/nodev2",
        data: {
          devEUI: deveui.replace(/\s/g, "").trim(),
          type: appName,
        },
      })
        .then(res => {
          setCheck(false);
          setLoading(false);
        })
        .catch(e => {
          setCheck(true);
          setLoading(false);
          alert(e.response.data.body.message);
        });
    } else if (middlewareId == 9) {
      setCheck(false);
      setLoading(false);
    } else if (middlewareId == 10) {
      setCheck(false);
      setLoading(false);
    } else if (middlewareId == 4 || middlewareId == 6) {
      setCheck(false);
      setLoading(false);
    } else if (middlewareId == 7) {
      api({
        method: "POST",
        url: "/check/node/bogor",
        data: {
          devEUI: deveui.replace(/\s/g, "").trim(),
          type: appName,
        },
      })
        .then(res => {
          setCheck(false);
          setLoading(false);
        })
        .catch(e => {
          setCheck(true);
          setLoading(false);
          alert(e.response.data.body.message);
        });
    } else if (middlewareId == 8) {
      api({
        method: "POST",
        url: "/check/node/helium",
        data: {
          devEUI: deveui.replace(/\s/g, "").trim(),
          type: appName,
        },
      })
        .then(res => {
          setCheck(false);
          setLoading(false);
        })
        .catch(e => {
          setCheck(true);
          setLoading(false);
          alert(e.response.data.body.message);
        });
    }
  };

  const addCompany = async e => {
    e.preventDefault();
    if (middlewareId == 3) {
      api({
        method: "POST",
        url: "/register/node/antares",
        data: {
          mdw: {
            device_name: deviceName,
            ri: antaresId,
            deveui: deveui.replace(/\s/g, "").trim(),
            node_type: typeId,
            antares_id: antaresId,
            child_app_name: childAppName,
            child_sn: childSn,
            branch: branch,
          },
          node: {
            middlewareId: middlewareId,
            application_name: deviceName,
            devEUI: deveui.replace(/\s/g, "").trim(),
            typeId: typeId,
            antares_id: antaresId,
          },
        },
      })
        .then(res => {
          alert("sukses add node");
        })
        .catch(e => {
          alert(e.message);
        });
    } else if (middlewareId == 1) {
      api({
        method: "POST",
        url: "/register/node",
        data: {
          middlewareId: middlewareId,
          application_name: appName,
          devEUI: deveui.replace(/\s/g, "").trim(),
          typeId: typeId,
          child_app_name: childAppName,
          child_sn: childSn,
          branch: branch,
        },
      })
        .then(res => {
          console.log(res.data);
          if (res.data.responseCode === 200) {
            alert(res.data.messages);
            window.location.reload();
          } else {
            alert(res.data.messages);
          }
        })
        .catch(e => {
          alert(e.response.data.messages);
        });
    } else if (middlewareId == 5 || middlewareId == 9 || middlewareId == 10) {
      api({
        method: "POST",
        url: "/register/nodev2",
        data: {
          middlewareId: middlewareId,
          application_name: appName,
          devEUI: deveui.replace(/\s/g, "").trim(),
          typeId: typeId,
          child_app_name: childAppName,
          child_sn: childSn,
          branch: branch,
        },
      })
        .then(res => {
          console.log(res.data);
          if (res.data.responseCode === 200) {
            alert(res.data.messages);
            window.location.reload();
          } else {
            alert(res.data.messages);
          }
        })
        .catch(e => {
          alert(e.response.data.messages);
        });
    } else if (middlewareId == 4 || middlewareId == 6) {
      api({
        method: "POST",
        url: "/register/node",
        data: {
          middlewareId: middlewareId,
          application_name: deviceName,
          devEUI: deveui.replace(/\s/g, "").trim(),
          typeId: typeId,
          wmc_user: wmcusername,
          wmc_pass: wmcpassword,
          child_app_name: childAppName,
          child_sn: childSn,
          branch: branch,
        },
      })
        .then(res => {
          console.log(res.data);
          if (res.data.responseCode === 200) {
            alert(res.data.messages);
            window.location.reload();
          } else {
            alert(res.data.messages);
          }
        })
        .catch(e => {
          alert(e.response.data.messages);
        });
    } else if (middlewareId == 7) {
      api({
        method: "POST",
        url: "/register/nodev2",
        data: {
          middlewareId: middlewareId,
          application_name: appName,
          devEUI: deveui.replace(/\s/g, "").trim(),
          typeId: typeId,
          child_app_name: childAppName,
          child_sn: childSn,
          branch: branch,
        },
      })
        .then(res => {
          console.log(res.data);
          if (res.data.responseCode === 200) {
            alert(res.data.messages);
            window.location.reload();
          } else {
            alert(res.data.messages);
          }
        })
        .catch(e => {
          alert(e.response.data.messages);
        });
    } else if (middlewareId == 8) {
      api({
        method: "POST",
        url: "/register/nodev2",
        data: {
          middlewareId: middlewareId,
          application_name: appName,
          devEUI: deveui.replace(/\s/g, "").trim(),
          typeId: typeId,
          child_app_name: childAppName,
          child_sn: childSn,
          branch: branch,
        },
      })
        .then(res => {
          console.log(res.data);
          if (res.data.responseCode === 200) {
            alert(res.data.messages);
            window.location.reload();
          } else {
            alert(res.data.messages);
          }
        })
        .catch(e => {
          alert(e.response.data.messages);
        });
    }
  };

  const handleChange = name => event => {
    setState({
      ...state,
      [name]: event.target.value,
    });
    console.log(state);
  };

  React.useEffect(() => {
    if (deveui !== "" && middlewareId !== "") {
      const getAppName = async () => {
        setLoading(true);
        try {
          let d = await api({
            url: `/v1/check/appname/${deveui
              .replace(/\s/g, "")
              .trim()}/${middlewareId}`,
            method: "GET",
          });
          setLoading(false);
          console.log(d.data, "DATA");
          setAppname(d.data.data.application);
        } catch (error) {
          // getAppName();
          setLoading(false);
        }
      };
      let time = setTimeout(() => {
        getAppName();
      }, 1000);

      return () => {
        clearTimeout(time);
      };
    }
  }, [deveui, middlewareId]);

  // console.log(typeId, "TYPE ID");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-around",
      }}
    >
      <Typography variant="h6">Register Node</Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "",
            flexWrap: "wrap",
            justifyContent: "space-around",
            width: "90%",
          }}
        >
          <FormControl
            style={{ marginTop: "5%", width: "100%" }}
            size="small"
            variant="outlined"
          >
            <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
              Middleware
            </InputLabel>
            <Select
              required
              error={middlewareId == "" ? true : false}
              native
              value={middlewareId}
              onChange={e => setMiddleware(e.target.value)}
              labelWidth={labelWidth}
            >
              <option value="" />
              {middlewares.map((val, index) => {
                return (
                  <option key={index} value={val.id}>
                    {val.name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          {/* value={deveui.toString().replace(/\d{4}(?=.)/g, '$&-')} */}

          <TextField
            required
            error={deveui == "" ? true : false}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 1 ? true : "none",
            }}
            onChange={e =>
              setDeveui(
                e.target.value
                  .replace(/\s/g, "")
                  .replace(/(.{4})/g, "$1 ")
                  .trim(),
              )
            }
            value={deveui}
            variant="outlined"
            size="small"
            label="devEUI"
          />
          <TextField
            required
            error={appName == "" ? true : false}
            onChange={e => setAppname(e.target.value)}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 1 ? true : "none",
            }}
            variant="outlined"
            size="small"
            label="Application Name"
            disabled
            value={appName}
          />
          <FormControl
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 1 ? true : "none",
            }}
            size="small"
            variant="outlined"
          >
            <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
              Node Type
            </InputLabel>
            <Select
              required
              error={typeId == "" ? true : false}
              native
              value={typeId}
              onChange={e => setType(e.target.value)}
              labelWidth={labelWidth}
            >
              <option value="" />
              {types.map((val, index) => {
                return (
                  <option key={index} value={val.id}>
                    {val.type_name}
                  </option>
                );
              })}
            </Select>
          </FormControl>

          {/* iki form =============================== */}

          <TextField
            required
            error={deveui == "" ? true : false}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 5 ? true : "none",
            }}
            onChange={e =>
              setDeveui(
                e.target.value
                  .replace(/\s/g, "")
                  .replace(/(.{4})/g, "$1 ")
                  .trim(),
              )
            }
            value={deveui}
            variant="outlined"
            size="small"
            label="devEUI"
          />
          <TextField
            required
            error={appName == "" ? true : false}
            onChange={e => setAppname(e.target.value)}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 5 ? true : "none",
            }}
            variant="outlined"
            size="small"
            label="Application Name"
            disabled
            value={appName}
          />
          <FormControl
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 5 ? true : "none",
            }}
            size="small"
            variant="outlined"
          >
            <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
              Node Type
            </InputLabel>
            <Select
              required
              error={typeId == "" ? true : false}
              native
              value={typeId}
              onChange={e => setType(e.target.value)}
              labelWidth={labelWidth}
            >
              <option value="" />
              {types.map((val, index) => {
                return (
                  <option key={index} value={val.id}>
                    {val.type_name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          {/* iki form =============================== */}

          {/* 9 form =============================== */}

          <TextField
            required
            error={deveui == "" ? true : false}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 9 ? true : "none",
            }}
            onChange={e =>
              setDeveui(
                e.target.value
                  .replace(/\s/g, "")
                  .replace(/(.{4})/g, "$1 ")
                  .trim(),
              )
            }
            value={deveui}
            variant="outlined"
            size="small"
            label="devEUI"
          />
          <TextField
            required
            error={appName == "" ? true : false}
            onChange={e => setAppname(e.target.value)}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 9 ? true : "none",
            }}
            variant="outlined"
            size="small"
            label="Application Name"
            disabled
            value={appName}
          />
          <FormControl
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 9 ? true : "none",
            }}
            size="small"
            variant="outlined"
          >
            <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
              Node Type
            </InputLabel>
            <Select
              required
              error={typeId == "" ? true : false}
              native
              value={typeId}
              onChange={e => setType(e.target.value)}
              labelWidth={labelWidth}
            >
              <option value="" />
              {types.map((val, index) => {
                return (
                  <option key={index} value={val.id}>
                    {val.type_name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          {/* 9 form =============================== */}

          {/* 10 form =============================== */}

          <TextField
            required
            error={deveui == "" ? true : false}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 10 ? true : "none",
            }}
            onChange={e =>
              setDeveui(
                e.target.value
                  .replace(/\s/g, "")
                  .replace(/(.{4})/g, "$1 ")
                  .trim(),
              )
            }
            value={deveui}
            variant="outlined"
            size="small"
            label="devEUI"
          />
          <TextField
            required
            error={appName == "" ? true : false}
            onChange={e => setAppname(e.target.value)}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 10 ? true : "none",
            }}
            variant="outlined"
            size="small"
            label="Application Name"
            disabled
            value={appName}
          />
          <FormControl
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 10 ? true : "none",
            }}
            size="small"
            variant="outlined"
          >
            <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
              Node Type
            </InputLabel>
            <Select
              required
              error={typeId == "" ? true : false}
              native
              value={typeId}
              onChange={e => setType(e.target.value)}
              labelWidth={labelWidth}
            >
              <option value="" />
              {types.map((val, index) => {
                return (
                  <option key={index} value={val.id}>
                    {val.type_name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          {/* 10 form =============================== */}

          {/* Helium form =============================== */}

          <TextField
            required
            error={appName == "" ? true : false}
            onChange={e => setAppname(e.target.value)}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 8 ? true : "none",
            }}
            variant="outlined"
            size="small"
            label="Application Name"
          />
          <TextField
            required
            error={deveui == "" ? true : false}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 8 ? true : "none",
            }}
            onChange={e =>
              setDeveui(
                e.target.value
                  .replace(/\s/g, "")
                  .replace(/(.{4})/g, "$1 ")
                  .trim(),
              )
            }
            value={deveui}
            variant="outlined"
            size="small"
            label="devEUI"
          />
          <FormControl
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 8 ? true : "none",
            }}
            size="small"
            variant="outlined"
          >
            <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
              Node Type
            </InputLabel>
            <Select
              required
              error={typeId == "" ? true : false}
              native
              value={typeId}
              onChange={e => setType(e.target.value)}
              labelWidth={labelWidth}
            >
              <option value="" />
              {types.map((val, index) => {
                return (
                  <option key={index} value={val.id}>
                    {val.type_name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          {/* Helium form =============================== */}

          {/* bogor form =============================== */}

          <TextField
            required
            error={deveui == "" ? true : false}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 7 ? true : "none",
            }}
            onChange={e =>
              setDeveui(
                e.target.value
                  .replace(/\s/g, "")
                  .replace(/(.{4})/g, "$1 ")
                  .trim(),
              )
            }
            value={deveui}
            variant="outlined"
            size="small"
            label="devEUI"
          />
          <TextField
            required
            error={appName == "" ? true : false}
            onChange={e => setAppname(e.target.value)}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 7 ? true : "none",
            }}
            variant="outlined"
            size="small"
            label="Application Name"
            disabled
            value={appName}
          />
          <FormControl
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 7 ? true : "none",
            }}
            size="small"
            variant="outlined"
          >
            <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
              Node Type
            </InputLabel>
            <Select
              required
              error={typeId == "" ? true : false}
              native
              value={typeId}
              onChange={e => setType(e.target.value)}
              labelWidth={labelWidth}
            >
              <option value="" />
              {types.map((val, index) => {
                return (
                  <option key={index} value={val.id}>
                    {val.type_name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          {/* bogor form =============================== */}

          {/* wmc form =============================== */}
          <TextField
            required
            error={childAppName == "" ? true : false}
            onChange={e => setChildAppName(e.target.value)}
            value={childAppName}
            style={{
              marginTop: "5%",
              width: "100%",
              display:
                typeId == 16 || typeId == 25 || typeId == 26 ? true : "none",
            }}
            variant="outlined"
            size="small"
            label="Slave Application Name"
          />

          <TextField
            required
            error={childSn == "" ? true : false}
            onChange={e => setChildSn(e.target.value)}
            value={childSn}
            style={{
              marginTop: "5%",
              width: "100%",
              display:
                typeId == 16 || typeId == 25 || typeId == 26 ? true : "none",
            }}
            variant="outlined"
            size="small"
            label="Slave SN"
          />
          <TextField
            required
            error={wmcusername == "" ? true : false}
            onChange={e => setWmcUsername(e.target.value)}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 4 ? true : "none",
            }}
            variant="outlined"
            size="small"
            label="Wmc Username"
          />
          <TextField
            required
            error={wmcpassword == "" ? true : false}
            onChange={e => setWmcPassword(e.target.value)}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 4 ? true : "none",
            }}
            variant="outlined"
            size="small"
            label="Wmc Password"
          />
          <TextField
            required
            error={appName == "" ? true : false}
            onChange={e => setDeviceName(e.target.value)}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 4 ? true : "none",
            }}
            variant="outlined"
            size="small"
            label="Device Name"
          />

          <TextField
            required
            error={appName == "" ? true : false}
            onChange={e =>
              setDeveui(
                e.target.value
                  .replace(/\s/g, "")
                  .replace(/(.{4})/g, "$1 ")
                  .trim(),
              )
            }
            value={deveui}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 4 ? true : "none",
            }}
            variant="outlined"
            size="small"
            label="devEUI"
          />
          <FormControl
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 4 ? true : "none",
            }}
            size="small"
            variant="outlined"
          >
            <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
              Node Type
            </InputLabel>
            <Select
              required
              error={typeId == "" ? true : false}
              native
              value={typeId}
              onChange={e => setType(e.target.value)}
              labelWidth={labelWidth}
            >
              <option value="" />
              {types.map((val, index) => {
                return (
                  <option key={index} value={val.id}>
                    {val.type_name}
                  </option>
                );
              })}
            </Select>
          </FormControl>

          {/* wmc joyanta form =============================== */}
          <TextField
            required
            error={appName == "" ? true : false}
            onChange={e => setDeviceName(e.target.value)}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 6 ? true : "none",
            }}
            variant="outlined"
            size="small"
            label="Device Name"
          />

          <TextField
            required
            error={appName == "" ? true : false}
            onChange={e =>
              setDeveui(
                e.target.value
                  .replace(/\s/g, "")
                  .replace(/(.{4})/g, "$1 ")
                  .trim(),
              )
            }
            value={deveui}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 6 ? true : "none",
            }}
            variant="outlined"
            size="small"
            label="devEUI"
          />
          <FormControl
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 6 ? true : "none",
            }}
            size="small"
            variant="outlined"
          >
            <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
              Node Type
            </InputLabel>
            <Select
              required
              error={typeId == "" ? true : false}
              native
              value={typeId}
              onChange={e => setType(e.target.value)}
              labelWidth={labelWidth}
            >
              <option value="" />
              {types.map((val, index) => {
                return (
                  <option key={index} value={val.id}>
                    {val.type_name}
                  </option>
                );
              })}
            </Select>
          </FormControl>

          {/* antares form =============================== */}
          <TextField
            required
            error={appName == "" ? true : false}
            onChange={e => setDeviceName(e.target.value)}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 3 ? true : "none",
            }}
            variant="outlined"
            size="small"
            label="Device Name"
          />

          <TextField
            required
            error={appName == "" ? true : false}
            onChange={e =>
              setDeveui(
                e.target.value
                  .replace(/\s/g, "")
                  .replace(/(.{4})/g, "$1 ")
                  .trim(),
              )
            }
            value={deveui}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 3 ? true : "none",
            }}
            variant="outlined"
            size="small"
            label="devEUI"
          />

          <TextField
            disabled
            value={antaresId}
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 3 ? true : "none",
            }}
            variant="outlined"
            size="small"
            label="Antares Id"
          />
          <FormControl
            style={{
              marginTop: "5%",
              width: "100%",
              display: middlewareId == 3 ? true : "none",
            }}
            size="small"
            variant="outlined"
          >
            <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
              Node Type
            </InputLabel>
            <Select
              required
              error={typeId == "" ? true : false}
              native
              value={typeId}
              onChange={e => setType(e.target.value)}
              labelWidth={labelWidth}
            >
              <option value="" />
              {types.map((val, index) => {
                return (
                  <option key={index} value={val.id}>
                    {val.type_name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl
            style={
              typeId != 1
                ? { display: "none" }
                : {
                    marginTop: "5%",
                    width: "100%",
                    display:
                      middlewareId == 1 ||
                      middlewareId == 5 ||
                      middlewareId == 7 ||
                      middlewareId == 9 ||
                      middlewareId == 10
                        ? true
                        : "none",
                  }
            }
            size="small"
            variant="outlined"
          >
            <InputLabel ref={inputLabel2} htmlFor="outlined-age-native-simple">
              Branch
            </InputLabel>
            <Select
              required
              // error={typeId == "" ? true : false}
              native
              value={branch}
              onChange={e => setBranch(e.target.value)}
              labelWidth={labelWidth2}
            >
              <option key={"sindcond"} value={"sindcond"}>
                SINDCON
              </option>
              <option key={"goldcard"} value={"goldcard"}>
                GOLDCARD
              </option>
            </Select>
          </FormControl>
        </div>
      </div>
      <div
        style={{
          marginTop: "5%",
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={props.onclose}
          variant="outlined"
          size="small"
          color="default"
        >
          cancel
        </Button>
        <Button
          disabled={saveDisable}
          onClick={addCompany}
          variant="outlined"
          size="small"
          style={{
            display: check == true ? "none" : "flex",
            marginLeft: "3%",
            backgroundColor: "#429C46",
            color: "white",
          }}
        >
          save
        </Button>

        <div
          style={{
            display: check == true ? "flex" : "none",
            marginLeft: "3%",
            backgroundColor: "#429C46",
            color: "white",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={checkNode}
          >
            check
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Cards;
