import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import AddNode from "./AddNode";
import AssignedNode from "./AssignedNode";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Grid,
  Modal,
  Backdrop,
  Fade,
  makeStyles,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import api from "../../URL/axiosConfig";
// components
import PageTitle from "../../components/PageTitle";
import ConfirmationModal from "../../components/Confimation/ConfirmationModal";
import Success from "../../components/message/Success";
import PageTitleMultipleButton from "../../components/PageTitle/PageTitleMultipleButton";
import ImportCSV from "../../components/ModalComponent/ImportCSV";
// import DropdownButton from "../../components/DropdownButton/DropdownButton";
import { Dropdown, Menu } from "antd";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ModalLogLastNode from "../../components/ModalComponent/ModalLogLastNode";
import ModalSetInterval from "../../components/ModalComponent/ModalSetInterval";
import ModalSetUtc from "../../components/ModalComponent/ModalSetUtc";
import ModalSetPulse from "../../components/ModalComponent/ModalSetPulse";
import ModalSetValve from "../../components/ModalComponent/ModalSetValve";
import ModalSetMeter from "../../components/ModalComponent/SetMeter";
import ModalSetTopup from "../../components/ModalComponent/ModalSetTopup";
import ModalSetSignal from "../../components/ModalComponent/ModalSignal";
import ModalDetailLocation from "../../components/ModalComponent/ModalDetailLocation";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "80%",
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "40%",
  },
}));

function Node(props) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [head, setHead] = React.useState([]);
  const [isBusy, setBusy] = React.useState(true);

  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);

  const [openS, setOpenS] = React.useState(false);
  const [successtext, setSuccesstext] = React.useState("Success");
  const [typeS, setTypeS] = React.useState("success");

  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(10);
  const [search, setSearch] = React.useState(null);
  const [total, setTotal] = React.useState(0);

  const [modalImport, setModalImport] = React.useState(false);

  const [id, setId] = React.useState(0);

  const [_detail, setDetail] = React.useState({});
  const [_modalLastLog, _setmodalLastLog] = React.useState(false);
  const [_modalSetInterval, _setModalInterval] = React.useState(false);
  const [_modalSetUtc, _setModalUtc] = React.useState(false);
  const [_modalSetPulse, _setModalPulse] = React.useState(false);
  const [_modalSetValve, _setModalValve] = React.useState(false);
  const [_modalSetMeter, _setModalMeter] = React.useState(false);
  const [_modalSetTopup, _setModalTopup] = React.useState(false);
  const [_modalSignal, _setModalSignal] = React.useState(false);
  const [__modalDetailLocation, _setModalDetailLocation] = React.useState(
    false,
  );

  const getData = () => {
    // console.log(page);
    setBusy(true);
    const fetchData = async () => {
      const res = await api({
        url: "/list/node",
        params: {
          page: page,
          size: size,
          search: search,
        },
      });
      // console.log("data", res.data);
      setTotal(res.data.total);
      const datas = res.data.messages ? res.data.messages : [];
      const resultArray = datas.map(key => ({
        application_name: key.application_name,
        devEUI: key.devEUI,
        typeName: key.weiots_master_type.type_name,
        middlewareName: key.weiots_middleware.name,
        companyName: key.weiots_company?.company_name,
        installationDate: key.installationDate
          ? moment(key.installationDate).format("DD-MM-YYYY HH:mm:ss")
          : null,
        childAppName: key.child_app_name,
        childSn: key.child_sn,
        action:
          key.companyId == null ? (
            <ButtonGroup variant="contained">
              <Button
                color="secondary"
                style={{ backgroundColor: "#E3184E" }}
                onClick={() => {
                  setId(key.id);
                  setOpen2(true);
                }}
              >
                Delete
              </Button>
              <Button
                style={{ marginLeft: "4px" }}
                color="secondary"
                onClick={() => {
                  setId(key.id);
                  setOpen4(true);
                }}
              >
                assigned
              </Button>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="1"
                      onClick={() => {
                        _setmodalLastLog(true);
                        setDetail(key);
                      }}
                    >
                      Log Last Node
                    </Menu.Item>
                    <Menu.Item
                      key="2"
                      onClick={() => {
                        _setModalInterval(true);
                        setDetail(key);
                      }}
                    >
                      Set Interval
                    </Menu.Item>
                    <Menu.Item
                      key="3"
                      onClick={() => {
                        _setModalUtc(true);
                        setDetail(key);
                      }}
                    >
                      Set UTC
                    </Menu.Item>
                    <Menu.Item
                      key="4"
                      onClick={() => {
                        _setModalPulse(true);
                        setDetail(key);
                      }}
                    >
                      Set Pulse
                    </Menu.Item>
                    <Menu.Item
                      key="5"
                      onClick={() => {
                        _setModalValve(true);
                        setDetail(key);
                      }}
                    >
                      Set Valve
                    </Menu.Item>
                    <Menu.Item
                      key="6"
                      onClick={() => {
                        _setModalMeter(true);
                        setDetail(key);
                      }}
                    >
                      Set Meter
                    </Menu.Item>
                    <Menu.Item
                      key="7"
                      onClick={() => {
                        _setModalTopup(true);
                        setDetail(key);
                      }}
                    >
                      Set Topup
                    </Menu.Item>
                    <Menu.Item
                      key="8"
                      onClick={() => {
                        _setModalSignal(true);
                        setDetail(key);
                      }}
                    >
                      Show Signal
                    </Menu.Item>
                    <Menu.Item
                      key="9"
                      onClick={() => {
                        _setModalDetailLocation(true);
                        setDetail(key);
                      }}
                    >
                      Detail Location
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button color="primary" size="small">
                  <ArrowDropDownIcon />
                </Button>
              </Dropdown>
            </ButtonGroup>
          ) : (
            <ButtonGroup variant="contained">
              <Button
                color="secondary"
                onClick={() => {
                  setId(key.id);

                  setOpen3(true);
                }}
              >
                Unsign
              </Button>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="1"
                      onClick={() => {
                        _setmodalLastLog(true);
                        setDetail(key);
                      }}
                    >
                      Log Last Node
                    </Menu.Item>
                    <Menu.Item
                      key="2"
                      onClick={() => {
                        _setModalInterval(true);
                        setDetail(key);
                      }}
                    >
                      Set Interval
                    </Menu.Item>
                    <Menu.Item
                      key="3"
                      onClick={() => {
                        _setModalUtc(true);
                        setDetail(key);
                      }}
                    >
                      Set UTC
                    </Menu.Item>
                    <Menu.Item
                      key="4"
                      onClick={() => {
                        _setModalPulse(true);
                        setDetail(key);
                      }}
                    >
                      Set Pulse
                    </Menu.Item>
                    <Menu.Item
                      key="5"
                      onClick={() => {
                        _setModalValve(true);
                        setDetail(key);
                      }}
                    >
                      Set Valve
                    </Menu.Item>
                    <Menu.Item
                      key="6"
                      onClick={() => {
                        _setModalMeter(true);
                        setDetail(key);
                      }}
                    >
                      Set Meter
                    </Menu.Item>
                    <Menu.Item
                      key="7"
                      onClick={() => {
                        _setModalTopup(true);
                        setDetail(key);
                      }}
                    >
                      Set Topup
                    </Menu.Item>
                    <Menu.Item
                      key="8"
                      onClick={() => {
                        _setModalSignal(true);
                        setDetail(key);
                      }}
                    >
                      Show Signal
                    </Menu.Item>
                    <Menu.Item
                      key="9"
                      onClick={() => {
                        _setModalDetailLocation(true);
                        setDetail(key);
                      }}
                    >
                      Detail Location
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button color="primary" size="small">
                  <ArrowDropDownIcon />
                </Button>
              </Dropdown>
            </ButtonGroup>
          ),
      }));
      var bodies = [];
      var bodyItems = [];
      resultArray.map(val => {
        Object.keys(val).forEach(function(val2) {
          bodyItems.push(val[val2]);
          // console.log(bodyItems);
        });
        bodies.push(bodyItems);
        bodyItems = [];
        // console.log(bodies);
      });
      if (!search) {
        let d = data;
        d = d.concat(bodies);
        d = d.slice(0, page * size);
        setData(d);
      } else {
        setData(bodies);
      }
      setBusy(false);

      // console.log(datas);
      var headItems = [
        "Application Name",
        "devEUI",
        "Type Name",
        "Middleware",
        "Company Name",
        "Installation Date",
        "Slave Application Name",
        "Slave Serial Number",
        "Action",
      ];
      setHead(headItems);
    };
    fetchData();
  };

  // console.log(_detail, "DATA");

  React.useEffect(() => {
    getData();
  }, [page, size, search]);
  const classes = useStyles();
  const handleModal = () => {
    setOpen(!open);
  };

  const SetUnsignNode = id1 => {
    api({
      url: "/ussigned/node/" + id1,
      method: "POST",
    })
      .then(ok => {
        setSuccesstext("Success Unsign Node");
        setOpenS(true);
        setTypeS("success");
        getData();
      })
      .catch(err => {
        setSuccesstext("Failed Unsign Node Please try again later");
        setOpenS(true);
        setTypeS("error");
        console.log(err);
      });
  };

  const SetDeleteNode = id1 => {
    // console.log(id1);
    api({
      url: "/delete/node/" + id1,
      method: "POST",
    })
      .then(ok => {
        setSuccesstext("Success Delete Node");
        setOpenS(true);
        setTypeS("success");
        getData();
      })
      .catch(err => {
        setSuccesstext("Failed Delete Node Please try again later");
        setOpenS(true);
        setTypeS("error");
        console.log(err);
      });
  };

  return (
    <>
      <ModalLogLastNode
        open={_modalLastLog}
        setOpen={_setmodalLastLog}
        node_id={_detail.id}
      />
      <ModalSetInterval
        open={_modalSetInterval}
        setOpen={_setModalInterval}
        devEui={_detail.devEUI}
        getData={getData}
      />
      <ModalSetUtc
        open={_modalSetUtc}
        setOpen={_setModalUtc}
        devEui={_detail.devEUI}
        getData={getData}
      />
      <ModalSetPulse
        open={_modalSetPulse}
        setOpen={_setModalPulse}
        devEui={_detail.devEUI}
        getData={getData}
      />
      <ModalSetValve
        open={_modalSetValve}
        setOpen={_setModalPulse}
        devEui={_detail.devEUI}
        getData={getData}
      />
      <ModalSetMeter
        open={_modalSetMeter}
        setOpen={_setModalMeter}
        devEui={_detail.devEUI}
        getData={getData}
      />
      <ModalSetTopup
        open={_modalSetTopup}
        setOpen={_setModalTopup}
        devEui={_detail.devEUI}
        getData={getData}
      />
      <ModalSetSignal
        open={_modalSignal}
        setOpen={_setModalSignal}
        devEui={_detail.devEUI}
        getData={getData}
      />
      <Success
        text={successtext}
        open={openS}
        setOpen={setOpenS}
        type={typeS}
      />
      <ConfirmationModal
        open={open2}
        setOpen={setOpen2}
        title="Are you sure to delete this Nodes?"
        desc="Node deleted can't be restore"
        onCancel={() => console.log("cancel")}
        onConfirm={() => SetDeleteNode(id)}
      />
      <ConfirmationModal
        open={open3}
        setOpen={setOpen3}
        title="Are you sure to Unsign this Nodes?"
        desc="Node unsign can't be restore"
        onCancel={() => console.log("cancel")}
        onConfirm={() => SetUnsignNode(id)}
      />
      <ModalDetailLocation
        open={__modalDetailLocation}
        setOpen={_setModalDetailLocation}
        node_id={_detail.id}
        getData={getData}
      />
      {/* <PageTitle title="Node" button="Add Node" modal={handleModal} /> */}
      <PageTitleMultipleButton
        button={[
          {
            buttonName: "Add Node",
            onClick: handleModal,
          },
          {
            buttonName: "Import CSV",
            onClick: () => setModalImport(true),
          },
        ]}
        title="Node"
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            data={data}
            columns={head}
            options={{
              filter: false,
              selectableRows: true,
              filterType: "dropdown",
              responsive: "stacked",
              rowsPerPage: size,
              page: 0,
              textLabels: {
                body: {
                  noMatch: isBusy ? (
                    <div>
                      <CircularProgress disableShrink />
                    </div>
                  ) : (
                    "Sorry, there is no matching data to display"
                  ),
                },
              },
              count: total,
              onSearchOpen: () => {
                setBusy(false);
              },
              onSearchChange: e => {
                setSearch(e);
                console.log(e);
              },
              onsearchClose: () => {
                setBusy(true);
              },
              download: false,
              onChangeRowsPerPage: e => {
                setSize(e);
              },
              onChangePage: e => {
                setPage(e + 1);
                // console.log(e);
              },
            }}
          />
        </Grid>
        {/* <Grid item xs={12}>
              <Widget title="Material-UI Table" upperTitle noBodyPadding>
                <Table data={mock.table} />
              </Widget>
            </Grid> */}
      </Grid>

      <ImportCSV
        modal={modalImport}
        setModal={setModalImport}
        getData={getData}
      />
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open4}
        onClose={() => {
          setOpen4(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open4}>
          <div className={classes.paper2}>
            <AssignedNode
              getdata={getData}
              onclose={() => {
                setOpen4(false);
              }}
              idnode={id}
            />
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper2}>
            <AddNode onclose={handleModal} />
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default withRouter(Node);
